@use 'variable/colors' as default-colors;
@use 'custom/variable/colors' as custom-colors;

// Cores de background
.bg-default {
  background-color: default-colors.$bg-default;
}

.bg-gray {
  background-color: default-colors.$bg-gray !important;
}

.bg-gray-light {
  background-color: default-colors.$bg-gray-light;
}

.bg-purple {
  background-color: default-colors.$bg-purple;
}

.bg-purple-light {
  background-color: default-colors.$bg-purple-light;
}

.bg-backdrop {
  background-color: default-colors.$bg-backdrop;
}

// ====================== Custom Colors
.bg-primary {
  background-color: custom-colors.$primary !important;
}

.bg-primary-light {
  background-color: custom-colors.$primary-light !important;
}

.bg-secondary {
  background-color: custom-colors.$secondary !important;
}

.bg-secondary-light {
  background-color: custom-colors.$secondary-light !important;
}

.bg-status-primary {
  background-color: custom-colors.$status-primary;
}

.bg-status-success {
  background-color: custom-colors.$status-success;
}

.bg-status-warning {
  background-color: custom-colors.$status-warning;
}

.bg-status-danger {
  background-color: custom-colors.$status-danger;
}

.bg-toast-light {
  background-color: custom-colors.$bg-toast-light;
}

.bg-toast {
  background-color: custom-colors.$bg-toast;
}

.bg-danger-light {
  background-color: custom-colors.$danger-light;
}

.bg-danger {
  background-color: custom-colors.$danger;
}

.bg-warning-light {
  background-color: custom-colors.$warning-light;
}

.bg-warning {
  background-color: custom-colors.$warning;
}

.bg-success-light {
  background-color: custom-colors.$success-light;
}

.bg-success {
  background-color: custom-colors.$success;
}

// Outras cores
.bg-blue {
  background-color: default-colors.$bg-blue;
}

.bg-brown {
  background-color: default-colors.$bg-brown;
}

.bg-red {
  background-color: default-colors.$bg-red;
}

.bg-orange {
  background-color: default-colors.$bg-orange;
}

.bg-pink {
  background-color: default-colors.$bg-pink;
}
