.loading-animation {
  animation-name: loading-animation;
  animation-duration: 1.25s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  width: max-content;
  height: max-content;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
