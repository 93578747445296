$lines: 1, 2, 3;

@each $line in $lines {
  .line-clamp-#{$line} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }
}
