@use 'variable/colors' as default-colors;

.scroll-y {
  overflow-y: auto !important;
  max-height: calc(100dvh - 13rem);

  &::-webkit-scrollbar {
    max-width: 0.2rem !important;
  }
}

.scroll-x {
  overflow-x: auto;

  &::-webkit-scrollbar {
    max-height: 0.2rem !important;
  }
}

.scroll-x,
.scroll-y {
  &::-webkit-scrollbar-track {
    background-color: default-colors.$bg-white;
    border-radius: 0.75rem !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: default-colors.$gray-2;
    width: 0.2rem !important;
    border-radius: 0.75rem !important;
  }
}
