@use 'variable/colors' as default-colors;
@use 'custom/variable/colors' as custom-colors;

.text-default {
  color: default-colors.$text-default;
}

.text-gray-0 {
  color: default-colors.$text-gray-0;
}

.text-gray-1 {
  color: default-colors.$text-gray-1;
}

.text-gray-2 {
  color: default-colors.$text-gray-2;
}

.text-gray-3 {
  color: default-colors.$text-gray-3;
}

.text-gray-4 {
  color: default-colors.$text-gray-4 !important;
}

// ====================== Custom Colors
.text-primary {
  color: custom-colors.$primary !important;
}

.text-secondary {
  color: custom-colors.$secondary !important;
}

.text-success {
  color: custom-colors.$success;
}

.text-warning {
  color: custom-colors.$warning;
}

.text-danger {
  color: custom-colors.$danger;
}
