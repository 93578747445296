@font-face {
  font-family: 'Custom Font';
  src:
    url('../../../fonts/font-primary/regular/OfficinaSerifOSITCTTBook.woff2')
      format('woff2'),
    url('../../../fonts/font-primary/regular/OfficinaSerifOSITCTTBook.woff')
      format('woff')
      url('../../../fonts/font-primary/regular/OfficinaSerifOSITCTTBook.ttf')
      format('truetype')
      url('../../../fonts/font-primary/regular/OfficinaSerifOSITCTTBook.eot');
  font-weight: 400;
}

@font-face {
  font-family: 'Custom Font';
  src:
    url('../../../fonts/font-primary/bold/OfficinaSerifOSITCTTBold.woff2')
      format('woff2'),
    url('../../../fonts/font-primary/bold/OfficinaSerifOSITCTTBold.woff')
      format('woff')
      url('../../../fonts/font-primary/bold/OfficinaSerifOSITCTTBold.ttf')
      format('truetype')
      url('../../../fonts/font-primary/bold/OfficinaSerifOSITCTTBold.eot');
  font-weight: 600;
}
