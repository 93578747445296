// Cores do projeto
$primary: #660099;
$primary-light: #{$primary}1a;
$secondary: #eb3c7d;
$secondary-light: #{$secondary}1a;

// Cores dos status
$status-primary: #2bc9d3;
$status-success: #67c839;
$status-warning: #eb9b3c;
$status-danger: $secondary;

// Cores do toast
$bg-toast-light: #71718b;
$bg-toast: #42425e;

// Outras cores
$danger-light: #fff2f2;
$danger: #d01313;
$warning-light: #eb9b3c;
$warning: #eb9b3c;
$success-light: #67c839;
$success: #f6fff1;

// Cor de arquivo
$bg-file: #f9faff;
