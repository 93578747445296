/*
  Observação! A ordem das cores é feita da mais clara para a mais escura.
*/

// Paleta de cores secundárias
$gray-0: #f4f5fa;
$gray-1: #e3e3ea;
$gray-2: #bbbbcf;
$gray-3: #71718b;
$gray-4: #42425e;

$purple-light: #ecefff;

// Cores de background
$bg-white: #ffffff;
$bg-default: $gray-0;
$bg-gray: $gray-1;
$bg-gray-light: $gray-0;
$bg-purple: #f9f9ff;
$bg-purple-light: #fbfbff;
$bg-backdrop: #0000001a;
$bg-blue: #004099;
$bg-brown: #673300;
$bg-red: #b40000;
$bg-orange: #b45100;
$bg-pink: #b4006f;

// Cores de texto
$text-white: #ffffff;
$text-default: $gray-4;
$text-gray-0: $gray-0;
$text-gray-1: $gray-1;
$text-gray-2: $gray-2;
$text-gray-3: $gray-3;
$text-gray-4: $gray-4;

// Cores de borda
$border: #e2e2e9;
