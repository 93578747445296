// default	  < 576px
// sm	        ≥ 576px
// md	        ≥ 768px
// lg	        ≥ 992px
// xl	        ≥ 1200px
// xxl	      ≥ 1400px
$sm: 576;
$md: 768;
$lg: 992;
$xl: 1200;
$xxl: 1400;

@mixin min-sm {
  @media screen and (min-width: #{$sm}px) {
    @content;
  }
}

@mixin min-md {
  @media screen and (min-width: #{$md}px) {
    @content;
  }
}

@mixin min-lg {
  @media screen and (min-width: #{$lg}px) {
    @content;
  }
}

@mixin min-xl {
  @media screen and (min-width: #{$xl}px) {
    @content;
  }
}

@mixin min-xxl {
  @media screen and (min-width: #{$xxl}px) {
    @content;
  }
}
